export const bankList = [
  { label: "ABB-Ngan hang TMCP An Binh" },
  { label: "ACB-Ngan hang TMCP A Chau" },
  { label: "Agribank-Ngan hang Nong nghiep va Phat trien nong thon" },
  { label: "ANZ-Ngan hang ANZ Viet Nam" },
  { label: "BVB-Ngan hang TMCP Bao Viet" },
  { label: "BC-Bank of China (Hong Kong) Limited" },
  { label: "BIDC-Ngan hang TMCP Dau tu va phat trien Campuchia" },
  { label: "BIDV-Ngan hang TMCP Dau tu va phat trien Viet Nam" },
  { label: "BNP-Ngan hang BNP Paribas CN Ha Noi" },
  { label: "BNP-Ngan hang BNP Paribas CN Ho Chi Minh" },
  { label: "BOC-Bank of Communications" },
  { label: "BPCE-Ngan hang BPCE" },
  { label: "JP-Ngan hang JPMorgan Chase" },
  { label: "CITI-Citi Bank CN Ha Noi" },
  { label: "CITI-Citi Bank CN Ho Chi Minh" },
  { label: "COOPBANK-Ngan hang Hop tac xa Viet Nam" },
  { label: "CTBC-Ngan hang TNHH CTBC CN TP Ho Chi Minh" },
  { label: "DB-DEUTSCHE BANK" },
  { label: "DBS-Ngan hang DBS" },
  { label: "DongA-Ngan hang TMCP Dong A" },
  { label: "Exim-Ngan hang TMCP XNK Viet Nam" },
  { label: "FCB-First Commercial Bank CN Ha Noi" },
  { label: "FCB-First Commercial Bank CN TP Ho Chi Minh" },
  { label: "GBBANK-NHTM TNHH MTV Dau khi Toan Cau Hoi So Chinh" },
  { label: "HD-Ngan hang TMCP Phat trien TP Ho Chi Minh" },
  { label: "HLO-Ngan hang HongLeong Viet Nam" },
  { label: "HNCB-Hua Nan Commercial Bank" },
  { label: "HSBC-Ngan hang TNHH Mot thanh vien HSBC Viet Nam" },
  { label: "IBK-Industrial Bank of Korea" },
  { label: "ICB-Industrial and Commercial Bank of China" },
  { label: "IVB-Indovina Bank" },
  { label: "KEB-Ngan hang KEB Hana CN Ha Noi" },
  { label: "KEB-Ngan hang KEB Hana CN TP Ho Chi Minh" },
  { label: "KIENLONG-Ngan hang TMCP Kien Long" },
  { label: "KMB-Ngan hang Kookmin" },
  { label: "LPB-NH TMCP Loc Phat Viet Nam" },
  { label: "MSB-Ngan hang TMCP Hang Hai Viet Nam" },
  { label: "Maybank-Malayan Banking Berhad TP HCM" },
  { label: "MB-Ngan hang TMCP Quan doi" },
  { label: "MUFG-Ngan hang TNHH MUFG - CN HCM" },
  { label: "MUFG-Ngan hang TNHH MUFG - CN Ha Noi" },
  { label: "MZH-Mizuho Bank CN Ha Noi" },
  { label: "MZH-Mizuho Bank CN TP Ho Chi Minh" },
  { label: "NAB-Ngan hang TMCP Nam A" },
  { label: "NASB-Ngan hang TMCP Bac A" },
  { label: "NCB-Ngan hang TMCP Quoc dan" },
  { label: "OCBC-Oversea - Chinese banking TP HCM" },
  { label: "Ocean-NH TM TNHH MTV Dai Duong CN Ha Noi" },
  { label: "OCB-Ngan hang TMCP Phuong Dong HCM" },
  { label: "PGB-Ngan Hang TMCP Thinh vuong va Phat trien CN Ha noi" },
  { label: "PVCom-Ngan hang TMCP Dai chung Viet Nam" },
  { label: "Sacom-Ngan hang TMCP Sai Gon thuong tin" },
  { label: "Saigonbank-Ngan hang TMCP Sai Gon cong thuong" },
  { label: "SCB-Ngan hang TMCP Sai Gon" },
  { label: "STANDARD-Ngan hang STANDARD CHARTERED" },
  { label: "SeAbank-Ngan hang TMCP Dong Nam A" },
  { label: "SHB-Ngan hang TMCP Sai Gon - Ha Noi" },
  { label: "Shinhan-Ngan hang TNHH MTV Shinhan" },
  { label: "SMBC-Sumitomo Mitsui Banking Corporation" },
  { label: "SPB-Ngan hang SinoPac CN TP HCM" },
  { label: "TCB-Ngan hang TMCP Ky thuong Viet Nam" },
  { label: "TFC-Ngan hang thuong mai Taipei Fubon CN Ha Noi" },
  { label: "TFC-Ngan hang thuong mai Taipei Fubon CN TP Ho Chi Minh" },
  { label: "UOB-Ngan hang TNHH MTV United Overseas Bank Viet Nam" },
  { label: "VBSP-NH Chinh sach xa hoi" },
  { label: "VDB-Ngan hang phat trien Viet Nam" },
  { label: "VIB-Ngan hang TMCP Quoc te" },
  { label: "VID-NH TNHH MTV Public Viet Nam" },
  { label: "VAB-Ngan hang TMCP Viet A" },
  { label: "VietBank-Ngan hang TMCP Viet Nam Thuong Tin" },
  { label: "Vietcapital-Ngan hang TMCP Ban Viet" },
  { label: "VCB-Ngan hang TMCP Ngoai thuong Viet Nam" },
  { label: "CB-NHTM TNHH MTV Xay dung Viet Nam" },
  { label: "VPBank-Ngan hang TMCP Viet Nam Thinh Vuong" },
  { label: "VRB-Ngan hang Lien doanh Viet Nga" },
  { label: "Vietin-Ngan hang TMCP Cong thuong Viet Nam" },
  { label: "Woori-Ngan hang Woori Viet Nam" },
  { label: "ABC-Ngan hang Agricultural Bank of China Limited" },
  { label: "MICB-Mega ICBC Bank TP HCM" },
];
