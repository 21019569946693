import { Modal } from "antd";

import { useEffect, useState } from "react";
import MapWithAutocomplete from "./MapWithAutocomplete";
// import "../styles/googleLocationModal.scss";

interface CoordAddress {
  address: string;
  lat: number;
  lng: number;
}

export const GoogleLocationModal = ({
  coord,
  visible,
  onClose,
  onSubmitOk,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmitOk: (coordAddress: CoordAddress) => void;
  coord?: CoordAddress;
}) => {
  const [loading, setLoading] = useState(false);
  const [addressInfo, setAddressInfo] = useState<CoordAddress>();
  const [originAddress, setOriginAddress] = useState<string | undefined>("");

  const handleSubmitForm = async () => {
    if (addressInfo) {
      onSubmitOk({ ...addressInfo });
      onClose();
    }
  };

  useEffect(() => {
    if (coord) {
      setOriginAddress(coord.address);
      setAddressInfo({
        address: coord?.address,
        lat: coord?.lat,
        lng: coord?.lng,
      });
    }
  }, [coord]);

  console.log(coord);

  return (
    <Modal
      className="address-modal"
      onCancel={onClose}
      visible={visible}
      title={<h1 className="text-primary text-lg m-0">Cập nhật địa chỉ</h1>}
      confirmLoading={loading}
      onOk={handleSubmitForm}
      width={600}
      cancelText={"Hủy"}
      okText={"Đồng ý"}
      okButtonProps={{
        disabled: addressInfo?.address == originAddress,
      }}
    >
      <MapWithAutocomplete
        inputPlaceholder={"Tìm kiếm vị trí"}
        coords={[{ lat: addressInfo?.lat || 0, lng: addressInfo?.lng || 0 }]}
        onPlaceSelected={(address: any) => setAddressInfo(address)}
      />
    </Modal>
  );
};
