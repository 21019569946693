export enum BusinessType {
  Personal = "PERSONAL", // Cá nhân
  Individual = "INDIVIDUAL", // Hộ kinh doanh cá thể
  Company = "COMPANY", // Doanh nghiệp
}

export const BusinessTypeTrans = {
  [BusinessType.Personal]: {
    value: BusinessType.Personal,
    label: "Cá nhân",
  },
  [BusinessType.Individual]: {
    value: BusinessType.Individual,
    label: "Hộ kinh doanh cá thể",
  },
  [BusinessType.Company]: {
    value: BusinessType.Company,
    label: "Doanh nghiệp",
  },
};
