import React, { useState } from "react";
import { Upload, message, Button, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { UploadFile } from "antd/lib";

const { Dragger } = Upload;

const WithdrawImportExcelModal = ({
  visible,
  onCancel,
  onSubmitOk,
}: {
  visible: boolean;
  onCancel: () => void;
  onSubmitOk: (notes: string[]) => void;
}) => {
  const [data, setData] = useState<string[]>([]); // Dữ liệu từ cột C3 đến cuối
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();

    reader.onload = (evt: any) => {
      const data = evt.target.result;
      const workbook = XLSX.read(data, { type: "binary" });

      // Giả sử sheet có tên là "Sheet1"
      const sheet = workbook.Sheets["Sheet1"];
      if (!sheet) {
        message.error("Không tìm thấy sheet 'Sheet1'");
        return;
      }

      // Chuyển dữ liệu sheet thành JSON (tất cả dữ liệu trong sheet)
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Lấy dữ liệu từ cột C (cột thứ 3 trong JSON), chỉ lấy các dòng có dữ liệu
      const extractedData = jsonData
        .slice(2) // Bắt đầu từ dòng 3 (index 2)
        .map((row: any) => row[2]) // Lấy giá trị cột C
        .filter((value: any) => value && value.trim() !== ""); // Lọc các dòng có dữ liệu (không rỗng)

      setData(extractedData); // Lưu dữ liệu vào state
    };

    reader.readAsBinaryString(file);
    return false; // Ngăn không cho `Upload` tự động upload file
  };

  const handleChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    if (newFileList.length === 0) {
      setFileList([]); // Xóa tất cả tệp
    } else {
      setFileList([newFileList[newFileList.length - 1]]); // Chỉ giữ lại file cuối cùng trong danh sách
    }
  };

  const handleClose = () => {
    onCancel();
    setData([]);
    setFileList([]);
  };

  return (
    <Modal
      title="Chọn File Excel để Nhập Dữ Liệu"
      visible={visible}
      onCancel={onCancel}
      width={600}
      okText="Duyệt hàng loạt"
      onOk={() => {
        onSubmitOk(data);
        handleClose();
      }}
      okButtonProps={{ disabled: data.length <= 0 }}
    >
      <div>
        <Dragger
          name="file"
          accept=".xlsx, .xls"
          beforeUpload={handleFileUpload}
          showUploadList={true}
          fileList={fileList}
          onChange={handleChange}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">
            Kéo và thả file vào đây hoặc nhấn để chọn file
          </p>
        </Dragger>

        {data.length > 0 && (
          <div>
            <h3>Thanh toán cho các yêu cầu với nội dung:</h3>
            <ul>
              {data.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WithdrawImportExcelModal;
