import settingJson from "settings.json";

export default {
  isDev: process.env.NODE_ENV == "development",
  version: settingJson.version,
  dateFormat: "DD/MM/YYYY",
  fullDateFormat: "HH:mm, DD/MM/YYYY",
  apiUrl: process.env.REACT_APP_API_URL,
  clientUrl: process.env.REACT_APP_CLIENT_URL + "/vi",
  avatarDefault: "/images/avatar-default.jpg",
  logoDefault: "/logo.png",
  googleMapApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
};
