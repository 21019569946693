import { ExportOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { bankList } from "utils/constants";
import { Withdraw, WithdrawStatus } from "types/withdraw";
import removeAccents from "remove-accents";
import useWithdraw from "hooks/useWithdraw";
import { withdrawApi } from "api/withdraw.api";

const WithdrawExportTemplateExcel = ({
  queryWithdraw,
}: {
  queryWithdraw: any;
}) => {
  const [allWithdraws, setAllWithdraws] = useState<Withdraw[]>([]);

  const {
    fetchData: fetchWithdraw,
    query: query,
    data: withdraws,
    loading: loadingWithdraw,
    total: totalWithdraw,
  } = useWithdraw({
    initialQuery: queryWithdraw,
  });

  const fetchAllWithdraws = async () => {
    let currentPage = 1;
    let allData: Withdraw[] = [];
    let totalPages = 1;

    while (currentPage <= totalPages) {
      const query = {
        ...queryWithdraw,
        page: currentPage,
        status: WithdrawStatus.Pending,
      };
      const { data } = await withdrawApi.findAll(query);

      allData = [...allData, ...data.withdraws];
      totalPages = Math.ceil(data.total / queryWithdraw.limit);
      currentPage++;
    }

    setAllWithdraws(allData);
    return allData;
  };

  // Hàm để tải file mẫu từ thư mục public
  const handleExport = async () => {
    try {
      const allData = await fetchAllWithdraws();
      // Đọc file mẫu từ thư mục public
      const response = await fetch("/excel/INTERBANK_LNH.xlsx");
      const arrayBuffer = await response.arrayBuffer();

      // Tạo Workbook từ file mẫu
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      const worksheet = workbook.getWorksheet(1) as ExcelJS.Worksheet;

      const bankListSheet = workbook.addWorksheet("BankList");
      bankList.forEach((bank, index) => {
        bankListSheet.getCell(`A${index + 1}`).value = bank.label;
      });

      worksheet?.getColumn("F").eachCell((cell, rowNumber) => {
        if (rowNumber >= 3) {
          cell.dataValidation = {
            type: "list",
            allowBlank: true,
            formulae: ["=BankList!$A$1:$A$1000"], // Tham chiếu đến Named Range
            showErrorMessage: true,
            error: "Vui lòng chọn từ danh sách",
          };
        }
      });

      allData.forEach((item, index) => {
        const rowIndex = index + 3; // Bắt đầu từ dòng 3
        worksheet.getCell(`A${rowIndex}`).value = index + 1; // STT tăng dần
        worksheet.getCell(`B${rowIndex}`).value = removeAccents(
          item.merchant.bankAccountName
        ); // Tên KH thụ hưởng
        worksheet.getCell(
          `C${rowIndex}`
        ).value = `Joy Bookie thanh toan yeu cau rut diem #${item.code}`;
        worksheet.getCell(`D${rowIndex}`).value = item.amount; // Số tiền
        worksheet.getCell(`E${rowIndex}`).value =
          item.merchant.bankAccountNumber; // Tài khoản thụ hưởng
        worksheet.getCell(`F${rowIndex}`).value = item.merchant.bankName; // Ngân hàng thụ hưởng
      });

      workbook.calcProperties.fullCalcOnLoad = true;

      // Tải xuống file mới với tên khác
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, "INTERBANK_LNH.xlsx");
    } catch (error) {
      console.error("Lỗi khi xuất file:", error);
    }
  };

  return (
    <div>
      <Button
        type="primary"
        loading={loadingWithdraw}
        icon={<ExportOutlined />}
        size="middle"
        onClick={handleExport}
      >
        Xuất Excel duyệt hàng loạt
      </Button>
    </div>
  );
};

export default WithdrawExportTemplateExcel;
