import { Col, Row } from "antd";

import { useMemo } from "react";
import { FaMotorcycle, FaStore, FaUserFriends } from "react-icons/fa";
import { ISummaryItem, SummaryItem } from "./SummaryItem";

export interface SummaryData {
  merchant: any;
  customer: number;
}

const StatsPage = ({ data }: { data?: SummaryData }) => {
  const generateSummary = (data?: SummaryData) => {
    const summary: ISummaryItem[] = [
      {
        colSpan: 12,
        title: "Cửa hàng",
        icon: <FaStore size={45} className="text-primary" />,
        total: data?.merchant.total || 0,
        tooltip: (
          <div className="flex flex-col gap-2">
            <div>Tổng cửa hàng đã duyệt:</div> {data?.merchant?.totalApprove}
            <div>Tổng cửa hàng bị từ chối: {data?.merchant?.totalReject}</div>
            <div>Tổng cửa hàng chờ duyệt: {data?.merchant?.totalPending}</div>
          </div>
        ),
      },
      {
        colSpan: 12,
        title: "Khách hàng",
        icon: <FaUserFriends size={45} className="text-primary" />,
        total: data?.customer || 0,
      },
    ];
    return summary;
  };

  const summaryData = useMemo(() => {
    return generateSummary(data);
  }, [data]);

  return (
    <>
      <Row gutter={[24, 24]}>
        {summaryData.map((item, index) => (
          <Col span={item.colSpan} key={index}>
            <SummaryItem data={item} key={index} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default StatsPage;
