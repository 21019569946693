import {
  CalendarOutlined,
  EuroCircleOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { LuTicket } from "react-icons/lu";
import { AdminLayout } from "layouts/AdminLayout";
import React from "react";
import { RouteObject } from "react-router-dom";
import { NotFoundPage } from "views/404/NotFoundPage";
import { CommissionConfigPage } from "views/CommissionConfig/CommissionConfigPage";
import { ConfigurationPage } from "views/Configuration/ConfigurationPage";
import { CustomerPage } from "views/Customer/CustomerPage";
import { DashboardPage } from "views/Dashboard/DashboardPage";
import { FeaturePage } from "views/Feature/FeaturePage";
import { LoginPage } from "views/Login/LoginPage";
import { MerchantPage } from "views/Merchant/MerchantPage";
import { NewsPage } from "views/News/NewsPage";
import { ProfilePage } from "views/Profile/ProfilePage";
import { RankPage } from "views/Rank/RankPage";
import { RenewPage } from "views/Renew/RenewPage";
import { RolePage } from "views/Role/RolePage";
import { StaffPage } from "views/Staff/StaffPage";
import { TagPage } from "views/Tag/TagPage";
import { MdDashboard } from "react-icons/md";
import { FaStore } from "react-icons/fa";
import { BiPackage } from "react-icons/bi";
import { GoHistory } from "react-icons/go";
import { IoPeople } from "react-icons/io5";
import { FaRegNewspaper } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { GrDocumentConfig } from "react-icons/gr";
import { FaTeamspeak } from "react-icons/fa";
import { TreatmentPage } from "views/Treatment/TreatmentPage";
import { MdHomeRepairService } from "react-icons/md";
import { BannerPage } from "views/Banner/BannerPage";
import { RiCoupon2Line, RiNewspaperLine } from "react-icons/ri";
import { CityPage } from "views/CityPage/CityPage";
import { LeadTopicPage } from "views/LeadTopicPage/LeadTopicPage";
import { BiSolidContact } from "react-icons/bi";
import { LeadPage } from "views/LeadPage/LeadPage";
import { ContentDefinePage } from "views/ContentDefine/ContentDefinePage";
import { FaqPage } from "views/FaqPage/FaqPage";
import { CustomerReviewPage } from "views/CustomerReviewPage/CustomerReviewPage";
import { CategoryPage } from "views/CategoryPage/CategoryPage";
import { PaymentTypePage } from "views/PaymentTypePage/PaymentTypePage";
import { CouponCampaignPage } from "views/CouponCampaignPage/CouponCampaignPage";
import { ReviewSamplePage } from "views/ReviewSample/ReviewSamplePage";
import { WithdrawPage } from "views/Withdraw/WithdrawPage";
import { VoucherPage } from "views/Voucher/VoucherPage";
import { RecordSalePage } from "views/RecordSale/RecordSalePage";
import { PaymentOrderPage } from "views/PaymentOrderPage/PaymentOrderPage";
import { BiMoneyWithdraw } from "react-icons/bi";
import { DictionaryPage } from "views/Dictionary/DictionaryPage";
import { HistoryCheckoutPage } from "views/HistoryCheckoutPage/HistoryCheckoutPage";
import { ConversationBadge } from "./components/ConversationBadge";
import { AppointmentsPage } from "views/AppointmentsPage/AppointmentsPage";
import { PaymentSourcePage } from "views/PaymentSourcePage/PaymentSourcePage";

export type AppRoute = Omit<RouteObject, "children"> & {
  title?: any;
  icon?: React.ReactNode;
  name?: string;
  description?: string;
  breadcrumb?: string;
  isAccess?: boolean;
};

export type Route = AppRoute & {
  children?: AppRoute[];
};

export const adminRoutes: Route[] = [
  {
    title: "Dashboard",
    icon: <MdDashboard />,
    path: "/dashboard",
    name: "dashboard",
    breadcrumb: "Dashboard",
    element: <DashboardPage title="Dashboard" />,
  },
  {
    title: "Cửa hàng",
    icon: <FaStore />,
    path: "/merchant",
    name: "merchant",
    breadcrumb: "Cửa hàng",
    element: (
      <MerchantPage
        title="Cửa hàng"
        description="Danh sách thông tin các cửa hàng"
      />
    ),
  },
  {
    title: "Danh sách lịch đặt",
    path: "/list",
    name: "list",
    icon: <CalendarOutlined />,
    breadcrumb: "Danh sách lịch đặt",
    element: (
      <AppointmentsPage
        title="Danh sách lịch đặt"
        description="Danh sách thông tin các lịch đặt của các cửa hàng"
      />
    ),
  },
  {
    title: "Lịch sử thanh toán",
    icon: <GoHistory />,
    path: "/payment-order",
    name: "payment-order",
    breadcrumb: "Lịch sử thanh toán",
    element: (
      <HistoryCheckoutPage
        title="Lịch sử thanh toán"
        description="Danh sách thông tin lịch sử thanh toán"
      />
    ),
  },
  {
    title: "Yêu cầu rút điểm",
    icon: <BiMoneyWithdraw />,
    path: "/withdraw",
    name: "withdraw",
    breadcrumb: "Yêu cầu rút điểm",
    description: "Tổng hợp các yêu cầu rút điểm của các Spa",
    element: (
      <WithdrawPage
        title="Yêu cầu rút điểm"
        description="Tổng hợp các yêu cầu rút điểm của các Spa"
      />
    ),
  },
  // {
  //   title: "Gia hạn",
  //   icon: <BiPackage />,
  //   path: "renew",
  //   name: "renew",
  //   breadcrumb: "Gia hạn",
  //   element: <RenewPage title="Renew" />,
  // },
  {
    title: "Khách hàng",
    breadcrumb: "Khách hàng",
    icon: <IoPeople />,
    path: "/customer",
    name: "customer",
    element: (
      <CustomerPage
        title="Khách hàng"
        description="Danh sách thông tin khách hàng"
      />
    ),
  },

  // {
  //   title: "Yêu cầu rút điểm",
  //   breadcrumb: "Yêu cầu rút điểm",
  //   icon: <EuroCircleOutlined />,
  //   path: "/withdraw",
  //   name: "withdraw",
  //   element: <WithdrawPage title="Yêu cầu rút điểm" />,
  // },
  {
    title: "Thanh toán hoa hồng",
    breadcrumb: "Thanh toán hoa hồng",
    icon: <EuroCircleOutlined />,
    path: "/record-sale",
    name: "record-sale",
    element: (
      <RecordSalePage
        title="Thanh toán tiền hoa hồng"
        description="Danh sách phí Joy Bookie và tình trạng thanh toán của đối tác"
      />
    ),
  },
  {
    title: "Voucher",
    breadcrumb: "Voucher",
    icon: <LuTicket />,
    path: "/voucher",
    name: "voucher",
    element: (
      <VoucherPage
        title="Voucher"
        description="Xem danh sách thông tin các voucher"
      />
    ),
  },
  {
    title: "Truyền thông",
    breadcrumb: "Truyền thông",
    icon: <RiNewspaperLine className="mr-2" />,
    path: "/media",
    name: "media",
    children: [
      {
        title: "Tin tức/Bài viết",
        breadcrumb: "Tin tức/Bài viết",
        // icon: <FaRegNewspaper />,
        path: "news",
        name: "news",
        element: (
          <NewsPage
            title="Tin tức/Bài viết"
            description="Xem danh sách thông tin các tin tức/bài viết"
          />
        ),
      },
      {
        title: "Banner",
        breadcrumb: "Banner",
        element: (
          <BannerPage title="Banner" description="Xem danh sách các Banner" />
        ),
        path: "banner",
        name: "banner",
      },

      // {
      //   title: "Cá nhân",
      //   breadcrumb: "Cá nhân",
      //   path: "profile",
      //   name: "profile",
      //   element: <ProfilePage title="Profile" />,
      // },
    ],
  },
  // {
  //   title: "Tin tức/Bài viết",
  //   breadcrumb: "Tin tức/Bài viết",
  //   icon: <FaRegNewspaper />,
  //   path: "/news",
  //   name: "news",
  //   element: <NewsPage title="Tin tức/Bài viết" />,
  // },
  {
    title: <ConversationBadge title="Liên hệ" />,
    breadcrumb: "Liên hệ",
    icon: <BiSolidContact />,
    path: "/lead",
    name: "lead",
    element: (
      <LeadPage
        title="Liên hệ"
        description="Danh sách nội dung liên hệ của người dùng gửi về cho Joy Bookie"
      />
    ),
  },
  // {
  //   title: "Thứ hạng",
  //   breadcrumb: "Thứ hạng",
  //   icon: <FaRankingStar />,
  //   path: "/rank",
  //   name: "rank",
  //   element: <RankPage title="Thứ hạng" />,
  // },
  // {
  //   title: "Lịch sử thanh toán",
  //   breadcrumb: "Lịch sử thanh toán",
  //   icon: <EuroCircleOutlined />,
  //   path: "/payment-order",
  //   name: "payment-order",
  //   element: <PaymentOrderPage title="Lịch sử thanh toán" />,
  // },

  {
    title: "Cấu hình",
    breadcrumb: "Cấu hình",
    icon: <GrDocumentConfig className="mr-3" />,
    path: "/config",
    name: "config",
    children: [
      {
        title: "Chung",
        breadcrumb: "Chung",
        element: <ConfigurationPage title="Chung" />,
        path: "general",
        name: "general",
      },
      {
        title: "Tag",
        breadcrumb: "Tag",
        element: <TagPage title="Tag" />,
        path: "tag",
        name: "tag",
      },
      {
        title: "Thứ hạng",
        breadcrumb: "Thứ hạng",
        // icon: <FaRankingStar />,
        path: "rank",
        name: "rank",
        element: <RankPage title="Thứ hạng" />,
      },
      {
        title: "Coupon",
        path: "coupon-campaign",
        name: "coupon-campaign",
        breadcrumb: "Coupon",
        index: true,
        element: (
          <CouponCampaignPage
            title="Coupon"
            description="Xem danh sách Coupon"
          />
        ),
      },
      {
        title: "Nội dung",
        breadcrumb: "Nội dung",
        element: <ContentDefinePage title="Nội dung" />,
        path: "content",
        name: "content",
      },
      {
        title: "Nội dung giao diện",
        breadcrumb: "Nội dung giao diện",
        element: <DictionaryPage title="Nội dung giao diện" />,
        path: "dictionary",
        name: "dictionary",
      },
      {
        title: "Đánh giá",
        breadcrumb: "Đánh giá",
        element: <CustomerReviewPage title="Đánh giá" />,
        path: "customer-review",
        name: "customer-review",
      },
      {
        title: "Tiền hoa hồng",
        breadcrumb: "Tiền hoa hồng",
        element: (
          <CommissionConfigPage
            title="Tiền hoa hồng"
            description="Xem cấu hình phần trăm tiền hoa hồng"
          />
        ),
        path: "commission",
        name: "commission",
      },
      // {
      //   title: "Tính năng",
      //   breadcrumb: "Tính năng",
      //   element: <FeaturePage title="Tính năng" />,
      //   path: "feature",
      //   name: "feature",
      // },

      {
        title: "Tỉnh thành",
        breadcrumb: "Tỉnh thành",
        // icon: <MdHomeRepairService />,
        path: "city",
        name: "city",
        element: <CityPage title="Tỉnh thành" />,
      },
      {
        title: "Loại dịch vụ",
        breadcrumb: "Loại dịch vụ",
        // icon: <MdHomeRepairService />,
        path: "treatment",
        name: "treatment",
        element: (
          <TreatmentPage
            title="Loại dịch vụ"
            description="Xem danh sách các loại dịch vụ"
          />
        ),
      },
      {
        title: "Ngành dịch vụ",
        breadcrumb: "Ngành dịch vụ",
        // icon: <BiSolidCategory />,
        path: "category",
        name: "category",
        element: (
          <CategoryPage
            title="Ngành dịch vụ"
            description="Xem danh sách các ngành dịch vụ"
          />
        ),
      },
      {
        title: "Đánh giá mẫu",
        breadcrumb: "Đánh giá mẫu",
        element: (
          <ReviewSamplePage
            title="Đánh giá mẫu"
            description="Xem danh sách các đánh giá mẫu"
          />
        ),
        path: "review-sample",
        name: "review-sample",
      },
      {
        title: "Chủ đề liên lạc",
        breadcrumb: "Chủ đề liên lạc",
        element: <LeadTopicPage title="Chủ đề liên lạc" />,
        path: "lead-topic",
        name: "lead-topic",
      },
      {
        title: "Câu hỏi thường gặp",
        breadcrumb: "Câu hỏi thường gặp",
        element: <FaqPage title="Câu hỏi thường gặp" />,
        path: "faq",
        name: "faq",
      },
      {
        title: "Hình thức thanh toán",
        path: "paymentType",
        name: "paymentType",
        breadcrumb: "Hình thức thanh toán",
        element: <PaymentTypePage title="Hình thức thanh toán" />,
      },
      {
        title: "Nguồn thanh toán",
        path: "PaymentSource",
        name: "PaymentSource",
        breadcrumb: "Nguồn thanh toán",
        element: <PaymentSourcePage title="Nguồn thanh toán" />,
      },
      // {
      //   title: "Gói dịch vụ",
      //   // icon: <BiPackage />,
      //   path: "renew",
      //   name: "renew",
      //   breadcrumb: "Gói dịch vụ",
      //   element: <RenewPage title="Renew" />,
      // },
    ],
  },

  {
    title: "Nhân viên",
    breadcrumb: "Nhân viên",
    icon: <FaTeamspeak className="mr-3" />,
    path: "/staff",
    name: "staff",
    children: [
      {
        title: "DS Nhân viên",
        breadcrumb: "DS Nhân viên",
        element: (
          <StaffPage
            title="DS Nhân viên"
            description="Xem danh sách nhân viên"
          />
        ),
        path: "list",
        name: "staffList",
      },
      {
        title: "Quyền",
        breadcrumb: "Quyền",
        element: <RolePage title="Quyền" />,
        path: "permission",
        name: "permission",
      },
      {
        title: "Cá nhân",
        breadcrumb: "Cá nhân",
        path: "profile",
        name: "profile",
        element: <ProfilePage title="Profile" />,
      },
    ],
  },
];

const routes: Route[] = [
  {
    path: "/",
    element: <AdminLayout />,
    children: adminRoutes,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export { routes };
