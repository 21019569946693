import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  Libraries,
  LoadScript,
  StandaloneSearchBox,
  StandaloneSearchBoxProps,
} from "@react-google-maps/api";
import { Marker } from "./components/Marker";
import { Input } from "antd";
import { CoordAddress } from "./GoogleMapAutoComplete";
import { result } from "lodash";
import settings from "settings";

interface MapWithAutocompleteProps {
  defaultCenter?: {
    lat: number;
    lng: number;
  };
  defaultZoom?: number;
  coords?: GoogleMapReact.Coords[];
  onPlaceSelected?: (coordAddress: CoordAddress) => void;
  inputPlaceholder?: string;
}

interface MarkerProps {
  lat: number;
  lng: number;
}

const libraries: Libraries = ["places"];
const MapWithAutocomplete: React.FC<MapWithAutocompleteProps> = ({
  defaultCenter = { lat: 10.7787887, lng: 106.6674621 },
  defaultZoom = 12,
  onPlaceSelected,
  coords,
  inputPlaceholder,
}) => {
  const [center, setCenter] = useState(defaultCenter);
  const [marker, setMarker] = useState<MarkerProps | null>();
  const [address, setAddress] = useState<string>();
  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  console.log(coords);
  const handleSearchBoxLoad = (ref: google.maps.places.SearchBox | null) => {
    setSearchBox(ref);
  };

  const handlePlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        const { tmpCity, tmpDistrict, tmpWard, tmpStreet } =
          getPlaceDetailFromMap(
            place.address_components || [],
            place.formatted_address || ""
          );
        const location = place.geometry?.location;
        if (location) {
          setMarker({ lat: location.lat(), lng: location.lng() });
          setCenter({ lat: location.lat(), lng: location.lng() });
          onPlaceSelected?.({
            address: inputRef.current?.value,
            lat: location.lat(),
            lng: location.lng(),
            tmpCity,
            tmpDistrict,
            tmpWard,
            tmpStreet,
          });
          if (inputRef.current) {
            inputRef.current.value =
              place.formatted_address || place.name || "";
          }
        }
      }
    }
  };

  const handleMapClick = async ({ lat, lng }: { lat: number; lng: number }) => {
    setMarker({ lat, lng });
    setCenter({ lat, lng });
    const { address, result } = await getGeocode(lat, lng);
    console.log({ result });
    const { tmpCity, tmpDistrict, tmpWard, tmpStreet } = getPlaceDetailFromMap(
      result.address_components || [],
      result.formatted_address || ""
    );
    if (address) {
      onPlaceSelected?.({
        address,
        lat,
        lng,
        tmpCity,
        tmpDistrict,
        tmpWard,
        tmpStreet,
      });
    } else {
      onPlaceSelected?.({ lat, lng, tmpCity, tmpDistrict, tmpWard, tmpStreet });
    }

    if (inputRef.current) {
      inputRef.current.value = address;
    }
  };

  const getPlaceDetailFromMap = (
    address_components: any[],
    formatted_address: string
  ) => {
    const tmpCity = getCityFromGeocode(address_components);
    const tmpDistrict = getDistrictFromGeocode(address_components);
    const tmpWard = getWardFromFullAddress(formatted_address);
    const tmpStreet = getStreetFromGeocode(address_components);
    return { tmpCity, tmpDistrict, tmpWard, tmpStreet };
  };

  const getCityFromGeocode = (array: any[]): string => {
    const result = array.filter((o: { types: string | string[] }) => {
      return o.types.includes("administrative_area_level_1");
    });

    if (result.length > 0) {
      return result[0].long_name;
    }

    return "";
  };

  const getDistrictFromGeocode = (array: any[]): string => {
    const result = array.filter((o: { types: string | string[] }) => {
      return (
        o.types.includes("administrative_area_level_2") ||
        o.types.includes("locality")
      );
    });

    if (result.length > 0) {
      return result[0].long_name;
    }

    return "";
  };
  const getWardFromFullAddress = (address: string) => {
    const split = address.split(", ");
    let ward = "";

    const regex = /(phường|xã|thị trấn).+/gm;
    const regexName = /(phường|xã|thị trấn)/gm;
    for (const item of split) {
      if (regex.test(item.toLowerCase())) {
        ward = item.trim();
      }
    }
    if (!ward) {
      ward = split[split.length - 4] || "";
    }

    return ward;
  };
  const getStreetFromGeocode = (array: any[]): string => {
    let tmpStreet = "";
    const route = array.filter((o: { types: string | string[] }) => {
      return o.types.includes("route");
    });
    const streetNum = array.filter((o: { types: string | string[] }) => {
      return o.types.includes("street_number");
    });

    if (streetNum.length > 0) {
      tmpStreet += streetNum[0].long_name;
    }

    if (route.length > 0) {
      tmpStreet += " " + route[0].long_name;
    }

    return tmpStreet;
  };

  const getGeocode = async (lat: number, lng: number) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${settings.googleMapApiKey}`
    );
    const data = await response.json();
    if (data.status === "OK") {
      return {
        address: data.results[0]?.formatted_address || "",
        result: data.results[0],
      };
    } else {
      return { address: "", result: undefined };
    }
  };

  useEffect(() => {
    //Kiểm tra tọa độ hợp lệ, đảm bảo phải có lat, lng
    const coordsValid = coords?.every((item) => item.lat > 0 && item.lng > 0);

    console.log(coordsValid);
    if (coords && coordsValid) {
      coords.map((item) => {
        setMarker({ lat: item.lat, lng: item.lng });
        setCenter({ lat: item.lat, lng: item.lng });
      });
    }
  }, [coords]);

  console.log(coords);
  console.log(center);
  return (
    <LoadScript
      googleMapsApiKey={settings.googleMapApiKey || ""}
      libraries={libraries}
    >
      <StandaloneSearchBox
        onLoad={handleSearchBoxLoad}
        onPlacesChanged={handlePlacesChanged}
      >
        <input
          ref={inputRef}
          type="text"
          placeholder={inputPlaceholder}
          style={{
            boxSizing: "border-box",
            border: "1px solid #ccc",
            width: "100%",
            height: "40px",
            padding: "0 12px",
            borderRadius: "8px",
            fontSize: "14px",
            outline: "none",
            marginBottom: 8,
          }}
        />
      </StandaloneSearchBox>
      <div style={{ height: "350px", width: "100%" }}>
        {
          //@ts-ignore
          <GoogleMapReact
            bootstrapURLKeys={{
              key: settings.googleMapApiKey || "",
            }}
            center={center}
            zoom={defaultZoom}
            onClick={handleMapClick}
            options={{ fullscreenControl: false }}
          >
            {marker && <Marker lat={marker.lat} lng={marker.lng} />}
          </GoogleMapReact>
        }
      </div>
    </LoadScript>
  );
};

export default MapWithAutocomplete;
