import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const withdrawApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/withdraw",
      params,
    }),
  create: (data: any): AxiosPromise<any> =>
    request({
      url: "/v1/admin/withdraw",
      data,
      method: "post",
    }),
  update: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/withdraw/${id}`,
      method: "patch",
      data,
    }),
  delete: (id: number): AxiosPromise<any> =>
    request({
      url: `/v1/admin/withdraw/${id}`,
      method: "delete",
    }),
  complete: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/withdraw/${id}/complete`,
      method: "patch",
      data,
    }),
  completeAll: (data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/withdraw/complete/all`,
      method: "post",
      data,
    }),
  cancel: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/admin/withdraw/${id}/cancel`,
      method: "delete",
      data,
    }),
};
