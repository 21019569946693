import { QueryParam } from "types/query";
import { useFetchTableData } from "./useFetchTableData";
import { Treatment } from "types/treatment";
import { treatmentApi } from "api/treatment.api";
import { message } from "antd";
interface TreatmentQuery extends QueryParam {}
type Props = {
  initialQuery: TreatmentQuery;
};

const useTreatment = ({ initialQuery }: Props) => {
  const fetch = useFetchTableData<Treatment, TreatmentQuery>({
    initQuery: initialQuery,
    queryFunc: async (query) => {
      const data = await treatmentApi.findAll(query).then(({ data }) => data);
      return {
        data: data.treatments,
        total: data.total,
      };
    },
    deleteFunc: async (id) => {
      await treatmentApi.delete(id);
      message.success("Thao tác thành công!");
    },
    editFunc: async (id, data) => {
      await treatmentApi.update(id, data);
    },
    createFunc: async (data) => {
      await treatmentApi.create(data);
    },
  });

  const toggleHighlight = async (id: number, isHighlight?: boolean) => {
    await treatmentApi.update(id, { treatment: { isHighlight } });
    message.success("Thao tác thành công");
    fetch.fetchData();
  };

  const changePosition = async (
    treatments: { id: number; position: number }[]
  ) => {
    await treatmentApi.batch({ treatments });
    fetch.fetchData();
  };

  return { toggleHighlight, changePosition, ...fetch };
};

export default useTreatment;
