import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Space, Tooltip } from "antd";
import { NodeElement } from "rc-tree/lib/interface";
import CountUp from "react-countup";
import { formatVND } from "utils";

export interface ISummaryItem {
  colSpan: number;
  total: number;
  icon: NodeElement;
  today?: number;
  title: string;
  moneyCommission?: number;
  moneyCommissionStore?: number;
  moneyIncome?: number;
  moneyFinal?: number;
  tooltip?: any;
  realMoneyFinalStore?: number;
  tooltipOnClickEvent?: () => void;
}

export const SummaryItem = ({ data }: { data: ISummaryItem }) => {
  const {
    total,
    icon,
    title,
    today,
    moneyCommission,
    moneyIncome,
    moneyCommissionStore,
    moneyFinal,
    tooltip,
    tooltipOnClickEvent,
  } = data;
  const totalFinal = +(moneyCommission as number) + (moneyCommissionStore || 0);
  return (
    <Card
      style={{ height: "100%" }}
      bordered={false}
      size="small"
      className="dashboard-card"
    >
      <Space style={{ width: "100%", justifyContent: "space-between" }}>
        <div className={`dashboard-icon `}>{icon}</div>
        <div
          style={{
            textAlign: "right",
          }}
        >
          <span style={{ fontSize: 16, fontWeight: "500" }}>{title}</span>
          <div
            className="d-flex justify-content-end"
            style={{
              alignItems: "baseline",
              gap: 4,
              fontWeight: 500,
              color: "#666666",
              marginTop: 5,
            }}
          >
            <span
              style={{
                fontSize: 12,
              }}
            >
              <Tooltip title={tooltip}>
                Tổng{" "}
                {tooltip && (
                  <InfoCircleOutlined
                    hidden={!tooltip}
                    onClick={() => {
                      console.log(tooltipOnClickEvent);
                      tooltipOnClickEvent?.();
                    }}
                  />
                )}{" "}
                :
              </Tooltip>
            </span>
            <div className="my-2 w-1 inline-block"></div>
            <CountUp
              end={total}
              formattingFn={(value) => formatVND(value)}
              style={{
                fontSize: 18,
              }}
            ></CountUp>
          </div>
          {today && (
            <div
              className="d-flex justify-content-end"
              style={{
                alignItems: "baseline",
                gap: 4,
                fontWeight: 500,
                color: "#666666",
              }}
            >
              <span
                style={{
                  fontSize: 12,
                }}
              >
                <Tooltip title={tooltip}>
                  Hôm nay <InfoCircleOutlined hidden={!tooltip} /> :
                </Tooltip>
              </span>
              <CountUp
                end={today}
                formattingFn={(value) => formatVND(value)}
                style={{
                  fontSize: 18,
                }}
              ></CountUp>
            </div>
          )}
        </div>
      </Space>
    </Card>
  );
};
