import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Marker } from "./components/Marker";
import { GoogleMapAutoComplete } from "./GoogleMapAutoComplete";
import { CoordAddress } from "../Map/GoogleMapAutoComplete";
import settings from "settings";

interface IGoogleMaps {
  center?: GoogleMapReact.Coords;
  zoom?: number;
  coord: CoordAddress;
  onPlaceSelected?: (coordAddress: CoordAddress) => void;
}

export const GoogleMaps = ({
  center = {
    lat: 10.7787887,
    lng: 106.6674621,
  },
  coord,
  zoom = 11,
  onPlaceSelected,
}: IGoogleMaps) => {
  const [coordSelected, setCoordSelected] = useState<CoordAddress | undefined>(
    coord
  );

  const mapRef = useRef<any>(null);

  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };

  const handlePlaceSelect = (coordAddress: CoordAddress) => {
    setCoordSelected(coordAddress);
    onPlaceSelected?.(coordAddress);
  };

  useEffect(() => {
    if (coordSelected) {
      mapRef.current?.setCenter(coordSelected);

      console.log(coordSelected);
    }
  }, [coordSelected]);

  console.log(process.env.REACT_APP_GOOGLE_API_KEY);

  return (
    <div style={{ height: "300px", width: "600" }}>
      <GoogleMapAutoComplete
        defaultBounds={defaultBounds}
        onPlaceSelected={handlePlaceSelect}
      />

      <GoogleMapReact
        // bootstrapURLKeys={{ key: $googleApiKey || "" }}
        bootstrapURLKeys={{
          key: settings.googleMapApiKey || "",
          libraries: ["places"],
          language: "en",
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{ zoomControl: false }}
      >
        {coordSelected && (
          <Marker lat={coordSelected.lat} lng={coordSelected.lng} />
        )}
      </GoogleMapReact>
    </div>
  );
};
