import moment, { Moment } from "moment";
import { City, District, Ward } from "./address";
import { Category } from "./category";
import { MerchantGallery } from "./merchantGallery";
import { MerchantLocation } from "./merchantLocation";
import { RenewPackage } from "./renewPackage";
import { ZaloOAAccount } from "./zaloOA";
import { Dayjs } from "dayjs";

export interface Merchant {
  id: number;
  createdAt: number;
  updatedAt: number;
  name: string;
  lat: number;
  long: number;
  website: string;
  facebook: string;
  instagram: string;
  address: string;
  isDeleted: boolean;
  avatar: string;
  isBlock: boolean;
  isVisible: boolean;
  expiredAt: number;
  rateStar: number;
  rateCount: number;
  introduce: string;
  clientCanCancelOrReschedule: number;
  clientCanBookAppointmentBeforeStart: number;
  clientCanBookAppointmentRangeTime: number;
  timeSlotInterval: number;
  importantInfo: string;
  turnOnTextConfirmation: boolean;
  turnOnTextReminder: boolean;
  turnOnTextCancellation: boolean;
  turnOnTextNoShow: boolean;
  templateConfirmation: string;
  templateReminder: string;
  templateCancellation: string;
  namespace: string;
  signupImages: (File & { url: string })[];
  templateNoShow: string;
  isAccept: boolean;
  phone: string;
  email: string;
  countryCode: string;
  privateNote: string;
  merchantGalleries: Partial<MerchantGallery>[];
  isEnableSendSms: boolean;
  zaloOAAccount: ZaloOAAccount;
  district: District;
  city: City;
  ward: Ward;
  status: MerchantStatus;
  category: Category;
  bioImage: string;
  coverImage: string;
  renewPackage: RenewPackage;
  bio: string;
  shortDesc: string;
  totalBalance: number;
  balance: number;
  merchantLocations: MerchantLocation[];
  categories: Category[];
  bankName: string;
  bankAccountNumber: string;
  bankAccountName: string;
  CCCDDate: string | Dayjs;
}

export enum MerchantStatus {
  Pending = "PENDING",
  Approve = "APPROVE",
  Reject = "REJECT",
}

export const merchantStatusTrans = {
  [MerchantStatus.Approve]: {
    value: MerchantStatus.Approve,
    label: "Đã duyệt",
    color: "green",
  },
  [MerchantStatus.Reject]: {
    value: MerchantStatus.Reject,
    label: "Từ chối",
    color: "red",
  },
  [MerchantStatus.Pending]: {
    value: MerchantStatus.Pending,
    label: "Chờ duyệt",
    color: "orange",
  },
};
