import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Spin, Switch, Table } from "antd";
import { Pagination } from "components/Pagination";
import useTreatment from "hooks/useTreatment";
import { useEffect, useRef } from "react";
import { Treatment } from "types/treatment";
import { getTitle } from "utils";
import { TreatmentModal } from "./components/TreatmentModal";
import { TreatmentDndModal } from "./components/TreatmentDnd";
import { MdOutlineChangeCircle } from "react-icons/md";

const { Column } = Table;

export const TreatmentPage = ({ title = "", description = "" }) => {
  const {
    fetchData,
    query,
    data,
    loading,
    filterData,
    total,
    deleteData,
    toggleHighlight,
    changePosition,
  } = useTreatment({
    initialQuery: {
      page: 1,
      limit: 20,
    },
  });
  const modalRef = useRef<TreatmentModal>(null);
  const treatmentDndModalRef = useRef<TreatmentDndModal>(null);

  useEffect(() => {
    document.title = getTitle(title);
    fetchData();
  }, []);

  return (
    <section className="box">
      <div className="flex flex-col gap-1 mb-5">
        <h1 className="text-xl m-0 ">{title}</h1>
        <span className="font-medium">{description}</span>
      </div>
      <div className="filter-container">
        <Space>
          <div className="filter-item">
            <label htmlFor="">Tìm kiếm</label>
            <Input
              size="middle"
              onChange={(ev) => {
                filterData({ search: ev.currentTarget.value, page: 1 });
              }}
              placeholder="Tìm kiếm"
            />
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => fetchData()}
              type="primary"
              icon={<SearchOutlined />}
            >
              Tìm kiếm
            </Button>
          </div>

          <div className="filter-item btn">
            <Button
              onClick={() => {
                modalRef.current?.handleCreate();
              }}
              type="primary"
              icon={<PlusOutlined />}
            >
              Thêm mới
            </Button>
          </div>
        </Space>
      </div>

      <Spin spinning={loading}>
        <Table pagination={false} rowKey="id" dataSource={data}>
          <Column
            title={
              <Button
                onClick={() => treatmentDndModalRef.current?.handleOpen(data)}
                type="primary"
                icon={
                  <MdOutlineChangeCircle className="translate-y-0.5 mr-1" />
                }
              >
                Vị trí
              </Button>
            }
            align="center"
            width={40}
            className="drag-visible drag-icon"
            dataIndex={"no"}
            render={(_, record: Treatment) => <>{record.position}</>}
          />
          <Column
            width={150}
            align="center"
            title="Icon"
            dataIndex="icon"
            key="icon"
            render={(text) => (
              <div className="text-center">
                <img
                  src={text}
                  className="object-cover inline-block"
                  width={30}
                  height={30}
                />
              </div>
            )}
          />
          <Column
            title="Tên"
            dataIndex="name"
            key="name"
            render={(text, record: Treatment) => {
              return (
                <span
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                  className="text-secondary cursor-pointer hover:underline font-medium"
                >
                  {text}
                </span>
              );
            }}
          />
          <Column
            title="Nổi bật"
            align="center"
            width={150}
            dataIndex="isHighlight"
            key="isHighlight"
            render={(text, record: Treatment) => (
              <span>
                <Popconfirm
                  onConfirm={async () => {
                    await toggleHighlight(record.id, !record.isHighlight);
                  }}
                  title="Bạn có chắc chắn muốn thực hiện thao tác này?"
                >
                  <Switch
                    checked={record.isHighlight}
                    checkedChildren="Nổi bật"
                    unCheckedChildren="Nổi bật"
                  ></Switch>
                </Popconfirm>
                {/* <Tag color={text ? "green" : "red"}>
                    {text ? "Bật" : "Tắt"}
                  </Tag> */}
              </span>
            )}
          />
          <Column
            title="Ngành dịch vụ"
            align="center"
            width={150}
            render={(text, record: Treatment) => (
              <span>{record?.category.name}</span>
            )}
          />

          <Column
            width={150}
            title="Hành động"
            key="action"
            render={(text, record: Treatment) => (
              <Space>
                <Popconfirm
                  title="Bạn có chắc chắn muốn thực hiện thao tác?"
                  onConfirm={async () => {
                    await deleteData(record.id);
                  }}
                >
                  <Button type="primary" danger>
                    Xóa
                  </Button>
                </Popconfirm>
                <Button
                  type="primary"
                  onClick={() => {
                    modalRef.current?.handleUpdate(record);
                  }}
                >
                  Cập nhật
                </Button>
              </Space>
            )}
          />
        </Table>

        <Pagination
          currentPage={query.page}
          total={total}
          pageSize={query.limit}
          onChange={({ limit, page }) => {
            filterData({ limit, page });
          }}
        />
      </Spin>

      <TreatmentDndModal
        ref={treatmentDndModalRef}
        onSubmitOk={fetchData}
        changePosition={changePosition}
      />
      <TreatmentModal
        onSubmitOk={fetchData}
        onClose={() => {}}
        ref={modalRef}
      />
    </section>
  );
};
