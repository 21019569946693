import {
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { Rule } from "antd/lib/form";
import { merchantApi } from "api/merchant.api";
import { AddressSelect } from "components/AddressSelect/AddressSelect";
import { GenerateObjectUrl } from "components/GenerateObjectUrl/GenerateObjectUrl";
import MerchantCategorySelector from "components/Selector/MerchantCategorySelector";
import {
  RecommendedSize,
  SingleImageUpload,
} from "components/Upload/SingleImageUpload";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Merchant, MerchantStatus } from "types/merchant";
import { ModalStatus } from "types/modal";
import { requiredRule, wordAndNumberOnlyRule } from "utils/validate";
import { AddressModal } from "./AddressModal";
import { RECOMMENDED_SIZE } from "constant/size";
import useRenewPackage from "hooks/useRenewPackage";
import { RenewPackage } from "types/renewPackage";
import { BusinessType, BusinessTypeTrans } from "types/businessType";

const rules: Rule[] = [{ required: true }];

export interface ApproveMerchantModal {
  open: (merchant: Merchant) => void;
}
interface ApproveMerchantModalProps {
  onSubmitOk: () => void;
  renewPackages: RenewPackage[];
}

export const ApproveMerchantModal = React.forwardRef(
  ({ onSubmitOk, renewPackages }: ApproveMerchantModalProps, ref) => {
    const [form] = Form.useForm<
      Merchant & {
        position: string;
        password: string;
        categoryId: number;
        renewPackageId: number;
      }
    >();
    const [merchant, setMerchant] = useState<Merchant>();
    const [mainLoading, setMainLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const addressSelectRef = useRef<any>();
    const businessType = Form.useWatch("businessType", form);

    const fetchDetail = async (id: number) => {
      return merchantApi.detail(id).then(({ data }) => data as Merchant);
    };

    const [addressVisible, setAddressVisible] = useState(false);

    useImperativeHandle<any, ApproveMerchantModal>(
      ref,
      () => ({
        async open(merchant: Merchant) {
          try {
            setMainLoading(true);
            setVisible(true);
            const data = await fetchDetail(merchant.id);

            console.log(data);
            setMerchant(data);
            form.setFieldsValue({
              ...data,
              avatar: data?.avatar ? data.avatar : data?.signupImages?.[0]?.url,
              bioImage: data?.bioImage
                ? data.bioImage
                : data?.signupImages?.[1]?.url || data?.signupImages?.[0]?.url,
              coverImage: data?.coverImage
                ? data.coverImage
                : data?.signupImages?.[2]?.url ||
                  data?.signupImages?.[1]?.url ||
                  data?.signupImages?.[0]?.url,
              categoryId: data?.category?.id,
              renewPackageId: data?.renewPackage?.id,
            });
          } catch (error) {
            // setVisible(false);
          } finally {
            setMainLoading(false);
          }
        },
      }),
      []
    );

    useEffect(() => {
      if (merchant) {
        addressSelectRef.current.setValue(merchant);
      }
    }, [merchant]);

    const createData = async () => {
      setLoading(true);
      try {
        const { id, password, ...data } = form.getFieldsValue();
        await form.validateFields();
        await merchantApi.approve(id, {
          password,
          merchant: data,
        });
        setVisible(false);
        onSubmitOk();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    return (
      <>
        <Modal
          afterClose={() => {
            form.resetFields();
            setMerchant(undefined);
          }}
          onCancel={() => {
            setVisible(false);
          }}
          visible={visible}
          title={"Duyệt cửa hàng"}
          style={{ top: 20 }}
          width={1200}
          confirmLoading={loading}
          onOk={createData}
        >
          <Spin spinning={mainLoading}>
            <Form layout="vertical" form={form}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Typography.Title level={5} className="text-center">
                    Bổ sung thông tin tiệm
                  </Typography.Title>
                  <Typography.Title
                    level={4}
                    className="text-center !text-secondary !font-bold !mt-2"
                  >
                    {[merchant?.name, merchant?.phone]
                      .filter(Boolean)
                      .join(" - ")}
                  </Typography.Title>
                </Col>
                {!!merchant?.signupImages?.length && (
                  <Col span={24}>
                    <div className="text-[14px] font-semibold mb-2">
                      Hình ảnh đăng ký
                    </div>
                    <Row justify={"center"} gutter={[24, 24]}>
                      {merchant?.signupImages.map((image) => (
                        <Col span={4}>
                          <div className="border border-dashed border-[#d9d9d9] rounded-lg h-[100px]">
                            <Image
                              height={"100%"}
                              className="object-contain"
                              width={"100%"}
                              src={image.url}
                            ></Image>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                )}
                <Col span={24}>
                  <div className="text-[14px] font-semibold mb-2">
                    {" "}
                    <span style={{ color: "red" }}>* </span>Hình ảnh
                  </div>
                  <div className="flex items-center justify-center">
                    <div>
                      <div className="text-center">
                        <Form.Item noStyle shouldUpdate={true}>
                          {() => {
                            return (
                              <Form.Item
                                // label=""
                                name="avatar"
                                rules={[requiredRule]}
                              >
                                <SingleImageUpload
                                  uploadUrl={merchantApi.uploadUrl}
                                  onUploadOk={(path: string) => {
                                    form.setFieldsValue({
                                      avatar: path,
                                    });
                                  }}
                                  // uploadTitleBtn="Ảnh đại diện"
                                  imageUrl={form.getFieldValue("avatar")}
                                />
                                <div className="text-center font-semibold mt-1">
                                  Ảnh đại diện
                                </div>
                                <RecommendedSize
                                  className="text-xs"
                                  iconClassName="text-xs"
                                  size={RECOMMENDED_SIZE.merchantAvatar}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </div>
                    </div>
                    <div>
                      <div className="text-center">
                        <Form.Item noStyle shouldUpdate={true}>
                          {() => {
                            return (
                              <Form.Item
                                // label="Ảnh giới thiệu"Duyệt cửa hàng
                                name="bioImage"
                                rules={[requiredRule]}
                              >
                                <SingleImageUpload
                                  onUploadOk={(path: string) => {
                                    form.setFieldsValue({
                                      bioImage: path,
                                    });
                                  }}
                                  // uploadTitleBtn="Ảnh giới thiệu"
                                  imageUrl={form.getFieldValue("bioImage")}
                                />
                                <div className="text-center font-semibold mt-1">
                                  Ảnh giới thiệu
                                </div>
                                <RecommendedSize
                                  className="text-xs"
                                  iconClassName="text-xs"
                                  size={RECOMMENDED_SIZE.bioImage}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </div>
                    </div>
                    <div>
                      <div className="text-center">
                        <Form.Item noStyle shouldUpdate={true}>
                          {() => {
                            return (
                              <Form.Item
                                // label="Ảnh bìa"
                                name="coverImage"
                                rules={[requiredRule]}
                              >
                                <SingleImageUpload
                                  onUploadOk={(path: string) => {
                                    form.setFieldsValue({
                                      coverImage: path,
                                    });
                                  }}
                                  // uploadTitleBtn="Ảnh bìa"
                                  imageUrl={form.getFieldValue("coverImage")}
                                />
                                <div className="text-center font-semibold mt-1">
                                  Ảnh bìa
                                </div>
                                <RecommendedSize
                                  className="text-xs"
                                  iconClassName="text-xs"
                                  size={RECOMMENDED_SIZE.coverImage}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col span={12}>
                  <Form.Item name={"id"} hidden noStyle>
                    <Input></Input>
                  </Form.Item>
                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => (
                          <Form.Item
                            // className="!mb-0"
                            extra={
                              merchant?.status == MerchantStatus.Approve ? (
                                <GenerateObjectUrl
                                  link={getFieldValue("namespace")}
                                />
                              ) : null
                            }
                            label="Namespace"
                            name="namespace"
                            rules={[requiredRule, wordAndNumberOnlyRule]}
                            // style={{ marginBottom: 70 }}
                          >
                            <Input
                              placeholder="Nhập Namespace"
                              value={getFieldValue("namespace")}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>

                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[requiredRule]}
                        // style={{ marginBottom: 70 }}
                      >
                        <Input placeholder="Nhập email" />
                      </Form.Item>

                      <Form.Item
                        rules={rules}
                        required
                        label="Gói sử dụng"
                        name={"renewPackageId"}
                      >
                        <Select
                          // onChange={handleChangeCity}
                          style={{ width: "100%" }}
                          // onClear={() => {
                          //   clearDistrict();
                          //   clearWard();
                          // }}
                          allowClear
                          placeholder="Chọn gói"
                          showSearch
                          options={renewPackages.map((item) => {
                            return {
                              label: item.name,
                              value: item.id,
                            };
                          })}
                        ></Select>
                      </Form.Item>
                      <Form.Item
                        name={"password"}
                        label="Mật khẩu"
                        rules={[requiredRule]}
                      >
                        <Input.Password autoComplete="new-password"></Input.Password>
                      </Form.Item>
                      <Form.Item
                        className="address-select-input"
                        name={"categoryId"}
                        label="Ngành dịch vụ"
                        rules={[requiredRule]}
                      >
                        <MerchantCategorySelector></MerchantCategorySelector>
                      </Form.Item>
                      <Form.Item
                        name={"businessType"}
                        label="Loại hình kinh doanh"
                        rules={[requiredRule]}
                      >
                        <Select
                          showSearch
                          placeholder="Chọn loại hình kinh doanh"
                          style={{ width: "100%", height: 32 }}
                          options={Object.values(BusinessTypeTrans).map(
                            (item) => {
                              return {
                                label: item.label,
                                value: item.value,
                              };
                            }
                          )}
                        />
                      </Form.Item>
                      {businessType !== BusinessType.Personal && (
                        <Form.Item
                          label="Tên doanh nghiệp/tên hộ kinh doanh"
                          name="businessName"
                        >
                          <Input placeholder="Nhập vào tên doanh nghiệp/tên hộ kinh doanh" />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <AddressSelect
                    ref={addressSelectRef}
                    form={form}
                    onChange={function (data: any): void {}}
                  ></AddressSelect>

                  <Form.Item
                    // style={style}
                    label="Tên đường, số nhà"
                    name="address"
                    rules={[requiredRule]}
                  >
                    <Input placeholder="Nhập tên đường, số nhà"></Input>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => (
                      <Form.Item
                        label="Vị trí"
                        className="custom-link"
                        rules={[requiredRule]}
                        name={"lat"}
                      >
                        <div></div>
                        <Input
                          suffix={
                            getFieldValue("lat") ? (
                              // eslint-disable-next-line jsx-a11y/alt-text
                              <img
                                width={20}
                                className="object-cover"
                                height={20}
                                src={`/icon/${
                                  getFieldValue("lat")
                                    ? "success.png"
                                    : "error.png"
                                }`}
                              />
                            ) : (
                              <div className="w-[20px] h-[20px]"></div>
                            )
                          }
                          // status={getFieldValue("lat") ? undefined : "error"}
                          value={
                            getFieldValue("lat")
                              ? "Đã chọn vị trí"
                              : "Chưa chọn vị trí"
                          }
                          readOnly
                          style={{ height: "100%" }}
                          onChange={(e) => {
                            return false;
                          }}
                          addonAfter={
                            <Button
                              size="small"
                              className="location-btn"
                              style={{ height: "40px !important" }}
                              type="primary"
                              onClick={() => setAddressVisible(true)}
                            >
                              {merchant?.address ? "Update" : "Chọn vị trí"}
                            </Button>
                          }
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        {addressVisible && (
          <AddressModal
            merchant={merchant}
            visible={addressVisible}
            onClose={() => setAddressVisible(false)}
            merchantId={merchant?.id || 0}
            onSubmitOk={(address, lat, long) => {
              form.setFieldsValue({ position: address, lat, long });
            }}
          />
        )}
      </>
    );
  }
);
