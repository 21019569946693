import { Button, Form, FormInstance, FormItemProps, Input, Select } from "antd";
import { useWatch } from "antd/es/form/Form";
import { Rule } from "antd/lib/form";
import { GoogleLocationModal } from "components/Map/GoogleLocationModal";
import { CoordAddress } from "components/Map/GoogleMapAutoComplete";
import { useAddress } from "hooks/useAddress";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { AddressData, City, District, Ward } from "types/address";
import { slugify } from "utils/url";
import { requiredRule } from "utils/validate";

const rules: Rule[] = [{ required: true }];

export interface AddressParam {
  parentCode?: string;
}

export interface AddressSelect {
  setValue: (data: IAddress) => void;
  set: (data: Partial<IAddress>) => void;
}

export interface IAddress {
  district: District;
  city: City;
  ward: Ward;

  // address: string;
}

export const AddressSelect = React.forwardRef(
  (
    {
      formItemProps,
      form,
      onChange,
      defaultValue,
      buttonText,
    }: {
      defaultValue?: IAddress;
      form: FormInstance<any>;
      onChange: (data: any) => void;
      formItemProps?: FormItemProps;
      buttonText?: string;
    },
    ref
  ) => {
    const [queryWard, setQueryWard] = useState<AddressParam>();
    const [queryDistrict, setQueryDistrict] = useState<AddressParam>();
    const {
      cities,
      districts,
      loading,
      wards,
      fetchCity,
      fetchDistrict,
      fetchWard,
      clearDistrict,
      clearWard,
      updateCity,
      updateDistrict,
      updateWard,
    } = useAddress();
    const [addressVisible, setAddressVisible] = useState(false);
    const address = useWatch("address", form);
    const lat = useWatch("lat", form);
    const long = useWatch("long", form);
    const [isMatching, setIsMatching] = useState(false);

    useImperativeHandle(
      ref,
      () => {
        return {
          setValue(data: IAddress) {
            if (data?.city) {
              updateCity([...cities, data.city]);
            }
            if (data?.district) {
              updateDistrict([...districts, data.district]);
            }
            if (data?.ward) {
              updateWard([...wards, data.ward]);
            }

            form.setFieldsValue({
              cityId: data?.city?.id,
              wardId: data?.ward?.id,
              districtId: data?.district?.id,
            });
          },
          set(data: Partial<IAddress>) {
            form.setFieldsValue({
              cityId: data?.city?.id,
              wardId: data?.ward?.id,
              districtId: data?.district?.id,
            });
            if (data?.city) {
              fetchDistrict({ parentCode: data.city.code });
            }
            if (data?.district) {
              fetchWard({ parentCode: data.district.code });
            }
          },
        };
      },
      [cities, wards, districts]
    );

    useEffect(() => {
      if (queryDistrict?.parentCode) {
        fetchDistrict(queryDistrict);
      }
    }, [queryDistrict]);

    useEffect(() => {
      if (queryWard?.parentCode) {
        fetchWard(queryWard);
      }
    }, [queryWard]);

    useEffect(() => {
      fetchCity().then((data) => {
        if (defaultValue) {
          if (defaultValue.city) {
            updateCity([...data, defaultValue.city]);
            setQueryDistrict({ parentCode: defaultValue.city.code });
          }
          if (defaultValue.district) {
            setQueryWard({ parentCode: defaultValue.district.code });
          }
          if (defaultValue.ward) {
            updateWard([...wards, defaultValue.ward]);
          }

          form.setFieldsValue({
            cityId: defaultValue?.city?.id,
            wardId: defaultValue?.ward?.id,
            districtId: defaultValue?.district?.id,
          });
        }
      });
    }, [defaultValue]);

    const handleChangeCity = (cityId: number) => {
      form.resetFields(["wardId", "districtId"]);
      if (cityId) {
        const code = cities.find((e) => e.id == cityId)?.code;
        setQueryDistrict({ parentCode: code });
      } else {
        clearDistrict();
      }
    };

    const handleChangeDistrict = (districtId: number) => {
      form.resetFields(["wardId"]);
      if (districtId) {
        const parentCode = districts.find((e) => e.id == districtId)?.code;
        setQueryWard({ parentCode });
      } else {
        clearWard();
      }
    };

    const handleSubmitAddress = (value: number) => {
      if (value) {
        const { districtId, cityId, wardId } = form.getFieldsValue();
        const data: AddressData = {
          district: districts.find((e) => e.id == districtId),
          ward: wards.find((e) => e.id == wardId),
          city: cities.find((e) => e.id == cityId),
        };
        onChange(data);
      } else {
        onChange(undefined);
      }
    };

    const updateSelectMatchWithAddress = async (addressInfo: CoordAddress) => {
      const { lat, lng, address, tmpCity, tmpDistrict, tmpWard, tmpStreet } =
        addressInfo;
      let findCity = undefined;
      let findDistrict = undefined;
      try {
        setIsMatching(true);
        const cities: City[] = await fetchCity();
        if (tmpCity) {
          let newTempCity = tmpCity;
          if (tmpCity.includes("City")) {
            newTempCity = tmpCity.replace("City", "");
          }
          findCity = cities.find((c) => {
            return (
              slugify(c.name.toLowerCase()) ==
              slugify(newTempCity.toLowerCase())
            );
          });
          if (findCity) {
            form.setFieldValue("cityId", findCity.id);
          } else {
            form.setFieldValue("cityId", undefined);
          }
        } else {
          form.setFieldValue("cityId", undefined);
        }
        let districts: District[] = [];
        if (findCity) {
          districts = await fetchDistrict({
            parentCode: findCity?.code,
          });
        }
        if (tmpDistrict) {
          console.log({ districts });
          findDistrict = districts.find(
            (c) =>
              slugify(c.name.toLowerCase()) ==
              slugify(tmpDistrict.toLowerCase())
          );
          if (findDistrict) {
            form.setFieldValue("districtId", findDistrict.id);
          } else {
            form.setFieldValue("districtId", undefined);
          }
        } else {
          form.setFieldValue("districtId", undefined);
        }
        let wards: Ward[] = [];
        if (findDistrict) {
          wards = await fetchWard({
            parentCode: findDistrict?.code,
          });
        }
        if (tmpWard) {
          const regexName = /(phường|xã|thị trấn)/gm;
          const wardWithoutPrefix = tmpWard
            .toLowerCase()
            .replace(regexName, "");
          console.log({ wardWithoutPrefix });
          const findWard = wards.find((c) => {
            const isWardNameNotNumber = isNaN(Number(c.name));
            return (
              slugify(
                isWardNameNotNumber
                  ? c.name.toLowerCase()
                  : Number(c.name).toString()
              ) == slugify(wardWithoutPrefix)
            );
          });
          if (findWard) {
            form.setFieldValue("wardId", findWard.id);
          } else {
            form.setFieldValue("wardId", undefined);
          }
        } else {
          form.setFieldValue("wardId", undefined);
        }
        if (tmpStreet) {
          form.setFieldValue("address", tmpStreet);
        } else {
          form.setFieldValue("address", undefined);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        setIsMatching(false);
      }
    };

    return (
      <div className="address-select-input">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => (
            <Form.Item
              label={<label className="!h-auto">Vị trí</label>}
              className="custom-link"
              rules={[requiredRule]}
              name={"lat"}
            >
              <div></div>
              <Input
                className="custom-input"
                size="small"
                suffix={
                  getFieldValue("lat") ? (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                      width={20}
                      className="object-cover"
                      height={20}
                      src={`/icon/${
                        getFieldValue("lat") ? "success.png" : "error.png"
                      }`}
                    />
                  ) : (
                    <div className="w-[20px] h-[20px]"></div>
                  )
                }
                // status={getFieldValue("lat") ? undefined : "error"}
                value={
                  getFieldValue("lat") ? "Đã chọn vị trí" : "Chưa chọn vị trí"
                }
                readOnly
                style={{ height: "100%" }}
                onChange={(e) => {
                  return false;
                }}
                addonAfter={
                  <Button
                    className="location-btn"
                    style={{ height: "42px !important" }}
                    type="primary"
                    onClick={() => setAddressVisible(true)}
                  >
                    {buttonText}
                  </Button>
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          {...formItemProps}
          rules={rules}
          required
          label="Tỉnh/Thành phố"
          name={"cityId"}
        >
          <Select
            onChange={handleChangeCity}
            style={{ width: "100%" }}
            onClear={() => {
              clearDistrict();
              clearWard();
            }}
            allowClear
            placeholder="Nhập tên tỉnh/thành phố"
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {cities?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.nameWithType}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          {...formItemProps}
          rules={rules}
          required
          label="Quận/Huyện"
          name={"districtId"}
        >
          <Select
            disabled={!districts.length}
            onClear={clearWard}
            onChange={handleChangeDistrict}
            style={{ width: "100%" }}
            allowClear
            placeholder="Nhập tên Quận/Huyện"
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {districts?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.nameWithType}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          {...formItemProps}
          rules={rules}
          required
          label="Xã/Phường"
          name={"wardId"}
        >
          <Select
            disabled={!wards.length}
            style={{ width: "100%" }}
            allowClear
            onChange={handleSubmitAddress}
            placeholder="Nhập tên Xã/Thị trấn"
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {wards?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.nameWithType}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Tên đường, số nhà"
          name="address"
          rules={[requiredRule]}
        >
          <Input placeholder="Nhập tên đường, số nhà"></Input>
        </Form.Item>
        <GoogleLocationModal
          coord={{ address, lat, lng: long }}
          // merchantLocation={merchantLocation}
          visible={addressVisible}
          onClose={() => {
            setAddressVisible(false);
            // setMerchantLocation(undefined);
          }}
          onSubmitOk={(addressInfo) => {
            updateSelectMatchWithAddress(addressInfo);
            console.log({ address, lat, long });
            form.setFieldsValue({
              position: addressInfo.address,
              lat: addressInfo.lat,
              long: addressInfo.lng,
            });
          }}
        />

        {/* </Form> */}
      </div>
    );
  }
);
