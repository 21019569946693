import { Form, Modal, Spin, Tabs, message } from "antd";
import { useEffect, useState } from "react";
import { Merchant, MerchantStatus } from "types/merchant";
import { ModalStatus } from "types/modal";
import { InfoTab } from "./TabPane/InfoTab";
import { StaffTab } from "./TabPane/StaffTab";
import { merchantApi } from "api/merchant.api";
import { MerchantFeaturePage } from "views/MerchantFeature/MerchantFeaturePage";
import useCategory from "hooks/useCategory";
import { MerchantTransactionTab } from "./TabPane/MerchantTransactionTab";
import { SummarySaleTab } from "./TabPane/Stats";
import { RenewPackage } from "types/renewPackage";
import { ServicesTab } from "./TabPane/ServicesTab";
import { PaymentOrderPage } from "views/PaymentOrderPage/PaymentOrderPage";
import { RecordSalePage } from "views/RecordSale/RecordSalePage";
import { CheckoutPage } from "views/CheckoutPage/CheckoutPage";
import { WithdrawPage } from "views/Withdraw/WithdrawPage";
import { ServicePackOrderPage } from "views/ServicePackOrderPage/ServicePackOrderPage";
import { WithdrawTab } from "./TabPane/WithdrawTab";
import dayjs from "dayjs";
import moment from "moment";

const { TabPane } = Tabs;

enum MerchantTabKeys {
  info = "INFO",
  feature = "FEATURE",
  employee = "EMPLOYEE",
  transaction = "TRANSACTION",
  stats = "STATS",
  services = "SERVICES",
  paymentOrder = "PAYMENT_ORDER",
  checkout = "CHECKOUT",
  withdraw = "WITHDRAW",
  servicePackOrder = "SERVICE_PACK_ORDER",
}

interface IMerchantForm extends Merchant {
  cityId: number;
  districtId: number;
  wardId: number;
  categoryId: number;
  categoryIds: number[];
  password: number;
  renewPackageId: number;
}

export const MerchantModal = ({
  isModalVisible,
  status,
  merchant,
  onSubmitOk,
  onClose,
  renewPackages,
}: {
  isModalVisible: boolean;
  status: ModalStatus;
  merchant?: Merchant;
  onSubmitOk: () => void;
  onClose: () => void;
  renewPackages: RenewPackage[];
}) => {
  const [activeKey, setActiveKey] = useState(MerchantTabKeys.info);
  const [form] = Form.useForm<IMerchantForm>();
  const [loading, setLoading] = useState(false);
  const [isFetchMerchantFindOneLoading, setIsFetchMerchantFindOneLoading] =
    useState(false);
  const [merchantDetail, setMerchantDetail] = useState<Merchant>();
  // const {
  //   fetchCategory,
  //   categories,
  //   deleteCategory,
  //   filterCategory,
  //   isFetchCategoryLoading,
  //   queryCategory,
  //   totalCategory,
  // } = useCategory({
  //   initialQuery: {
  //     page: 1,
  //     limit: 20,
  //   },
  // });

  // useEffect(() => {
  //   fetchCategory()
  // }, [])

  const getFormData = () => {
    const {
      cityId,
      districtId,
      wardId,
      password,
      categoryIds,
      renewPackageId,
      CCCDDate,
      ...merchant
    } = form.getFieldsValue();

    return {
      merchant: {
        ...merchant,
        CCCDDate: dayjs(CCCDDate).format("YYYY-MM-DD"),
      },
      cityId,
      districtId,
      wardId,
      password,
      categoryIds,
      renewPackageId,
    };
  };

  useEffect(() => {
    if (!isModalVisible) {
      setMerchantDetail(undefined);
      setIsFetchMerchantFindOneLoading(false);
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (isModalVisible) {
      if (status == "create") {
        form.resetFields();
      } else {
        form.setFieldsValue({
          ...merchant,
          CCCDDate: merchant?.CCCDDate ? dayjs(merchant?.CCCDDate) : null,
          categoryIds: merchant?.categories?.map((category) => category.id),
          renewPackageId: merchant?.renewPackage?.id,
        } as IMerchantForm);
      }
    }
    if (merchant) {
      handleFetchMerchantFindOne(merchant.id);
    }
    return () => {};
  }, [isModalVisible, status, merchant]);

  const handleFetchMerchantFindOne = async (id: number) => {
    setIsFetchMerchantFindOneLoading(true);
    try {
      const res = (await merchantApi.detail(id)).data as Merchant;
      setMerchantDetail(res);
    } finally {
      setIsFetchMerchantFindOneLoading(false);
    }
  };

  const handleSubmitForm = async () => {
    console.log(form.getFieldsValue());
    await form.validateFields();
    try {
      setLoading(true);
      const formData = getFormData();

      if (status == "create") {
        await merchantApi.create(formData);
      } else {
        await merchantApi.update(merchant?.id || 0, formData);
      }
      onSubmitOk();
      onClose();
      message.success("Thao tác thành công");
    } catch (error) {
      console.error(`Lỗi submit form merchant: `, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      confirmLoading={loading}
      title={
        <h1 className="text-secondary text-lg font-bold">
          {status === "create"
            ? "Thêm cửa hàng"
            : `Cập nhật cửa hàng - ${merchantDetail?.name || ""}`}
        </h1>
      }
      onCancel={() => {
        onClose();
      }}
      afterClose={() => {
        setActiveKey(MerchantTabKeys.info);
        form.resetFields();
      }}
      onOk={handleSubmitForm}
      style={{ top: 20 }}
      visible={isModalVisible}
      width={1200}
      destroyOnClose
      okButtonProps={{
        style: {
          display:
            merchantDetail?.status === MerchantStatus.Pending
              ? "none"
              : "unset",
        },
      }}
      cancelButtonProps={{
        style: {
          display:
            merchantDetail?.status === MerchantStatus.Pending
              ? "none"
              : "unset",
        },
      }}
    >
      <Spin
        spinning={isFetchMerchantFindOneLoading}
        className="w-full text-center"
      >
        {status === "create" ? (
          <Form form={form} layout="vertical">
            <InfoTab
              merchant={undefined}
              status={status}
              renewPackages={renewPackages}
            />
          </Form>
        ) : (
          <Tabs
            activeKey={activeKey}
            onTabClick={(key) => {
              setActiveKey(key as MerchantTabKeys);
            }}
          >
            <TabPane tab="Thông tin" key={MerchantTabKeys.info}>
              <Form form={form} layout="vertical">
                <InfoTab
                  merchant={merchantDetail}
                  status={status}
                  renewPackages={renewPackages}
                />
              </Form>
            </TabPane>
            <TabPane tab="Nhân viên" key={MerchantTabKeys.employee}>
              <StaffTab merchant={merchantDetail} />
            </TabPane>
            <TabPane tab="Dịch vụ" key={MerchantTabKeys.services}>
              <ServicesTab merchant={merchantDetail as Merchant} />
            </TabPane>
            <TabPane tab="Thống kê" key={MerchantTabKeys.stats}>
              <div className="flex flex-col gap-5">
                <div className="flex flex-col ">
                  <h3 className="my-2">Tổng doanh thu</h3>
                  <SummarySaleTab merchant={merchantDetail as Merchant} />
                </div>
                <div className="flex flex-col ">
                  <h3>Phí Joy Bookie</h3>
                  <RecordSalePage
                    merchantId={merchantDetail?.id}
                    isBoxStyle={false}
                    isShowAction={false}
                  />
                </div>
              </div>
            </TabPane>

            <TabPane tab="Checkout" key={MerchantTabKeys.checkout}>
              <CheckoutPage merchantId={merchantDetail?.id} />
            </TabPane>
            <TabPane
              tab="Lịch sử mua gói"
              key={MerchantTabKeys.servicePackOrder}
            >
              <ServicePackOrderPage merchantId={merchantDetail?.id} />
            </TabPane>
            <TabPane
              tab="Lịch sử thanh toán"
              key={MerchantTabKeys.paymentOrder}
            >
              <PaymentOrderPage merchantId={merchantDetail?.id} />
            </TabPane>
            <TabPane tab="Lịch sử ví" key={MerchantTabKeys.transaction}>
              <MerchantTransactionTab merchant={merchantDetail} />
            </TabPane>

            <TabPane tab="Lịch sử rút điểm" key={MerchantTabKeys.withdraw}>
              <WithdrawTab merchantId={merchantDetail?.id} />
            </TabPane>
          </Tabs>
        )}
      </Spin>
    </Modal>
  );
};
